h1, h2, h3, h4 {
  font-family: 'Gill Sans MT', 'Lato', sans-serif;
}

h1 {
  color: $bluedark;
  text-transform: uppercase;
}

h2 {
  color: $bluelogo;
  font-size: 1.6rem;
  text-transform: uppercase;
}

h3 {
  color: $bluelogo;
  font-size: 1.3rem;
}

.nav-link {
  font-size: 1.1rem;

  &:hover{
    background-color: $bluelogo;
    color: #fff !important;
    border-radius: 0.25rem;
  }
}

footer {
  font-size: 0.8rem;
}

a {
  color: $bluelogo;
}

.accentuatedDiv {
  background-color: rgba(34, 135, 194, 0.15);
  border-width: 2px !important;
}

//wieiswie.html
.wieiswieName {
  text-transform: none;
  font-size: 1.1rem;
  color: $bluelogo;
  font-weight: bold;
}

.card img{
  border-width: 2px !important;
}

//errorpagina's
.error {
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  section {
    min-width: 45%;
    background-color: rgba(255, 255, 255, 0.75);
    border-width: 3px !important;
  }
}

.error403 {
  background-image: url(../assets/images/angry-husky.jpg);
}

.error404 {
  background-image: url(../assets/images/sniffing-wieners.jpg);
}

.iframe-full-width{
  width: 95%;
  height: 350px;
  overflow: hidden;
  border: none;
}

.google-map iframe{
  border: 1px solid $bluelogo;
}

//NAVIGATIE
//groeiend logo-grootte: sm - md - lg - xl
@include media-breakpoint-up(sm) {
  .navbar-brand img{
    width: 300px;
  }
}

@include media-breakpoint-up(xl) {
  .navbar-brand img{
    width: 400px;
  }
}

//gecentreerde (hamburger)items
.navbar-collapse ul{
  text-align: center;
}

//wedstrijden-annulatie
.corona{
  text-decoration: line-through;
  color: lighten(grayscale($bluedark),50%);
}

// sticky header always visible
//header{
//  position: sticky;
//  top: 0;
//  z-index: 9999;
//}

//sponsor-tabel
#sponsors {
   align-content: center;

  div {
    padding: 1rem;

    img{
      box-shadow: 3px 3px 5px rgba(1, 21, 38,0.7);
    }
  }
}