//kleuren
$bluedark: #011526; //(1, 21, 38)
$bluelogo: #2287c2; //(34, 135, 194)
$light: #f3f4f9; //(243, 244, 249)

$theme-colors: (
        'bluedark': $bluedark,
        'bluelogo': $bluelogo,
);

$body-color: $bluedark;
$body-bg: $bluedark;

//fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap');
$font-family-sans-serif: 'Nunito', sans-serif;

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');